/**
 * author 文全
 */

import Vue from "vue";
import VueRouter from "vue-router";
import hooks from "./hooks";
import personal from "./personal";
import Activity from "./activity";
import homePage from "./homePage";
import accountCenter from "./accountCenter";
import Spt from "./spt";
import Contract from "./contract";
import Topics from "./topics";
import group from "./group";
import userManagement from "./user-management";
import bonus from "./bonus";
import coupon from "./coupon";
import pinMall from "./pinMall";
import friendAssistMall from "./friendAssistMall";
import rebateCoupon from "./rebateCoupon";
import userGift from "./userGift";
import distribution from "./distribution";
import tplPreview from "./tpl-preview";
import planetManage from "./planet_manage";
import assetsManage from "./assets-manage";
import mallManage from "./mall_manage";
import distributeManage from "./distribute_manage";
import operatingAssistant from "./operating-assistant";
import DashBoard from "./dashBoard";
import miniProgram from "./miniProgram";
import systemSetup from "./systemSetup";
import activitySign from "./activitySign";
import crystalRedPack from "./crystal-red-pack.js";
import signIn from "./signIn";
import planetCooperation from "./planet-cooperation";
import vip from "./vip";
import live from "./live";
import teamUp from "./teamUp";
import vod from "./vod";
import statisticsReport from "./statistics-report";
import community_manage from "./community_manage";
import markRedPack from "./mark-red-pack.js";
import plugin from "./plugin";
import ropsPackage from "./props-package";
import starCoCreationManage from "./star-co-creation-manage"; //星球共创管理
import ledger from "./ledger";
import ledgerWx from "./ledgerWx";
import giftCard from "./gift-card";
import eco from "./eco";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history", // 设置地址栏模式 默认是hash
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "",
      name: "open.name",
      component: () => import("@/views/open-platform/index"),
    },
    {
      path: "/xu_zheng_download_tools",
      name: "tools",
      meta: {
        name: "工具",
      },
      component: () => import("@/views/test/tools.vue"),
    },
    {
      path: "/mobile",
      name: "mobileIndex",
      component: () => import("@/views/mobile/index"),
      meta: {
        name: "手机端",
      },
    },
    {
      path: "/single-login",
      name: "无账号登录",
      component: () => import("@/views/login/single-login.vue"),
      meta: {
        name: "无账号登录",
      },
    },
    {
      path: "/test-oauth",
      name: "test-oauth",
      component: () => import("@/views/login/test-oauth.vue"),
      meta: {
        name: "测试OAuth",
      },
    },
    {
      path: "/login",
      name: "login.login.name",
      component: () => import("@/views/login/index"),
      meta: {
        name: "登录",
      },
    },
    {
      path: "/select-version",
      name: "选择版本",
      component: () => import("@/views/login/select-version"),
      meta: {
        name: "选择版本",
      },
    },
    {
      path: "/reset/:steps",
      name: "login.reset.name",
      component: () => import("@/views/login/reset"),
      meta: {
        name: "忘记密码",
      },
    },
    {
      path: "/regist/:steps",
      name: "login.regist.name",
      meta: {
        login: true,
        contract: true,
        name: "注册",
      },
      component: () => import("@/views/login/regist"),
    },
    {
      path: "/activation-status",
      name: "login.activationStatus.name",
      component: () => import("@/views/login/activation-status.vue"),
      meta: {
        name: "账号激活",
      },
    },
    {
      path: "/plugins/mobile/regist",
      name: "PluginsMobileRegist",
      component: () => import("@/views/plugins/mobile/regist.vue"),
      meta: {
        name: "开启水晶星球",
      },
    },
    {
      path: "/plugins/mobile/create-star",
      name: "PluginsMobileCreateStar",
      component: () => import("@/views/plugins/mobile/create-star.vue"),
      meta: {
        name: "创建星球",
      },
    },
    {
      path: "/plugins/mobile/success",
      name: "PluginsMobileSuccess",
      component: () => import("@/views/plugins/mobile/success.vue"),
      meta: {
        name: "创建星球",
      },
    },
    {
      path: "/plugins/mobile/enterprise-certification",
      name: "PluginsMobileEnterpriseCertification",
      component: () => import("@/views/plugins/mobile/enterprise-certification.vue"),
      meta: {
        name: "企业认证",
      },
    },
    {
      path: "/plugins/mobile/download",
      name: "PluginsMobileDownload",
      component: () => import("@/views/plugins/mobile/download.vue"),
      meta: {
        name: "企业认证",
      },
    },
    {
      path: "/main",
      name: "common.home",
      component: () => import("@/components/main"),
      children: [
        Activity,
        homePage,
        accountCenter,
        Topics,
        group,
        userManagement,
        bonus,
        coupon,
        pinMall,
        friendAssistMall,
        activitySign,
        userGift,
        distribution,
        rebateCoupon,
        planetManage,
        mallManage,
        distributeManage,
        operatingAssistant,
        DashBoard,
        miniProgram,
        systemSetup,
        signIn,
        teamUp,
        planetCooperation,
        vip,
        live,
        community_manage,
        crystalRedPack,
        vod,
        personal,
        statisticsReport,
        markRedPack,
        plugin,
        ropsPackage,
        ledger,
        ledgerWx,
        giftCard,
        eco,
        starCoCreationManage,
        assetsManage,
        {
          path: "spt",
          name: "common.spt",
          component: () => import("@/components/main-children"),
          children: Spt,
        },
      ],
    },

    {
      path: "/open/enterprise-certification",
      name: "open.company.name",
      meta: {
        login: true,
        contract: true,
      },
      component: () => import("@/views/open-platform/enterprise-certification"),
    },
    {
      path: "/open/enterprise-certification/success",
      name: "open.company.successName",
      component: () => import("@/views/open-platform/enterprise-certification-success"),
    },
    {
      path: "/starList",
      name: "starList",
      component: () => import("@/views/starList/index"),
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/components/error.vue"),
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/views/test/test.vue"),
    },
    {
      path: "/health",
      name: "health",
      component: () => import("@/views/health.vue"),
    },
    Contract,
    tplPreview,
    {
      path: "*",
      redirect: "/",
    },
  ],
});

/**
 * 每一次路由拦截钩子
 * 根 router.app 组件内部的在next中
 *
 */

hooks(router);

export default router;
