// import statisticsRouter from "./unit-statistics";
// console.log('9999',statisticsRouter)

export default {
  path: "order-statistics",
  name: "数据统计",
  component: () => import("@/components/main-children.vue"),
  children: [
    {
      path: "star-order-list",
      name: "星球订单表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/star-order-list"),
    },
    {
      path: "ps-star-order-list",
      name: "星球售后订单表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/ps-star-order-list"),
    },
    {
      path: "income-list",
      name: "分销业绩表",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/income-list"),
    },
    {
      path: "transation-statement-list",
      name: "交易对账单",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/transation-statement-list"),
    },
    {
      path: "accounting-records-list",
      name: "分账记录",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/accounting-records-list"),
    },
    {
      path: "publish-whitelist",
      name: "发布白名单数据统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/publish-whitelist"),
    },
    {
      path: "publish-whitelist-detail",
      name: "发布详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/publish-whitelist-detail"),
    },
    {
      path: "mark-score-statisitics",
      name: "分润统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/mark-score-statisitics"),
    },
    {
      path: "mark-score-members-statisitics",
      name: "本期会员分润统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/mark-score-members-statisitics"),
    },
    {
      path: "mark-score-statisitics-info",
      name: "会员分润统计详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/mark-score-members-statisitics"),
    },
    {
      path: "star-gross-profit",
      name: "星球毛利统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/star-gross-profit"),
    },
    {
      path: "mark-score-user-income",
      name: "会员收益记录",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/mark-score-user-income"),
    },
    {
      path: "partner-statistic",
      name: "合伙人统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/partner-statistic"),
    },
    {
      path: "partner-statistic-detail",
      name: "合伙人历史详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/components/common/partner-statistic-detail/list.vue"),
    },
    {
      path: "statistic-detail",
      name: "统计明细",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/eco/statistic-detail.vue"),
    },
    {
      path: "xplan-overview",
      name: "X计划概览",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/xplan-overview.vue"),
    },
    {
      path: "xplan-statistics",
      name: "X计划统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/components/xplan-statistics/list"),
    },
    {
      path: "partners",
      name: "合伙人数据",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/dashBoard/qitu-home-page/pages/partner.vue"),
    },
    {
      path: "partner-detail",
      name: "合伙人数据-详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/dashBoard/qitu-home-page/pages/partnerDetail.vue"),
    },
    {
      path: "projects",
      name: "项目数据",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/dashBoard/qitu-home-page/pages/project.vue"),
    },
    {
      path: "project-detail",
      name: "项目数据-详情",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/dashBoard/qitu-home-page/pages/projectDetail.vue"),
    },
    {
      path: "activity-statistics",
      name: "活动统计",
      meta: {
        login: true,
        Authentication: true,
        noVerifyToken: false,
      },
      component: () => import("@/views/order-statistics/activity-statisitics.vue"),
    },
  ],
};
